import Api from '@/Services/Api'

export default {
    async getTags() {
        let response = await Api().get('/tags-messagerie/liste-tags')
        return response.data
    },
    async addUpdateTag(datas) {
        let response = await Api().post('/tags-messagerie/ajout-modification-tag', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async deleteTag(datas) {
        let response = await Api().post('/tags-messagerie/suppression-tag', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async linkUsersTags(datas){
        let response = await Api().post('/tags-messagerie/lien-tags-users', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async getUserTags(){
        let response = await Api().get('/tags-messagerie/user-tags')
        return response.data
    },
    async removeUserTags(datas){
        let response = await Api().post('/tags-messagerie/suppression-user-tag', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    }
}
