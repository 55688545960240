<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Gestion des tags messagerie</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <h3>
              Liste des tags
            </h3>
            <v-list v-if="tags.length > 0">
              <v-row>
                <v-col cols="6"
                  ><v-btn
                    title="Ajout d'un tag"
                    @click="addTagForm()"
                    color="blue"
                    dark
                    block
                  >
                    Ajouter un tag
                  </v-btn>
                </v-col>
                <v-col cols="6"
                  ><v-btn
                    title="Attribution des tags"
                    @click="dialogAttributionTags = true"
                    color="success"
                    dark
                    block
                  >
                    Attribution
                  </v-btn></v-col
                >
              </v-row>
              <v-list-item
                v-for="tag in tags"
                :key="tag.id"
                class="tag-item primary"
                dark
              >
                <v-list-item-content>
                  <v-list-item-title
                    >{{ tag.tag.name }}
                    <div class="tag-actions">
                      <v-btn
                        color="blue"
                        dark
                        small
                        :class="{ 'mr-3': !tag.tag.default }"
                        title="Utilisateurs affectés"
                        :disabled="tag.users.length == 0"
                        @click="dialogUsersTagClick(tag)"
                      >
                        <v-icon>mdi-account-multiple</v-icon>
                      </v-btn>
                      <v-btn
                        color="orange"
                        dark
                        small
                        class="mr-3"
                        @click="updateTag(tag)"
                        v-if="!tag.tag.default"
                        title="Modifier"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        color="red"
                        dark
                        small
                        title="Supprimer"
                        v-if="!tag.tag.default"
                        @click="
                          dialogRemoveTag = true;
                          tagDeleting = tag;
                        "
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <div v-else>
              <v-btn
                title="Ajout d'un tag"
                @click="addTagForm()"
                color="blue"
                dark
                block
              >
                Ajouter un tag
              </v-btn>
              <v-alert color="orange" type="info"
                >Aucun tag, veuillez en ajouter en cliquant sur le '+'
                ci-dessus</v-alert
              >
            </div>
          </v-col>
          <v-col cols="9" v-if="form != ''">
            <v-form ref="form" v-model="valid" lazy-validation>
              <h3 class="mb-5">
                {{
                  form == 'add'
                    ? "Ajout d'un tag"
                    : 'Mise à jour du tag : ' + formTag.tag.name
                }}
              </h3>
              <v-row>
                <v-col cols="4"
                  ><v-text-field
                    label="Nom"
                    :rules="nameRules"
                    filled
                    v-model="formTag.tag.name"
                    @keyup="checkNotSameNameTag()"
                  ></v-text-field
                  ><v-alert v-if="sameNameTag" type="error">
                    {{ $t('messagerie.filtres.form.same_name') }}
                  </v-alert>
                </v-col>
                <v-col cols="6">
                  <v-switch-text
                    :labels="[
                      'Voir toutes les fiches',
                      'Voir les fiches me concernant',
                    ]"
                    v-model="formTag.tag.rights.seeAll"
                    color="primary"
                    class="mb-4"
                    outlined
                    as-toggle
                    small
                  >
                    <template v-slot:append-selected-item>
                      <v-icon small class="ml-2">mdi-check</v-icon>
                    </template>
                  </v-switch-text>

                  <v-switch-text
                    :labels="[
                      'Réponse en interne uniquement',
                      'Pouvoir répondre aux clients',
                    ]"
                    v-model="formTag.tag.rights.answerClient"
                    color="primary"
                    outlined
                    as-toggle
                    small
                  >
                    <template v-slot:append-selected-item>
                      <v-icon small class="ml-2">mdi-check</v-icon>
                    </template>
                  </v-switch-text>
                </v-col>
                <v-col cols="2">
                  <v-checkbox
                    v-model="formTag.tag.detailed_view"
                    label="Vue détaillée"
                    disabled
                  ></v-checkbox>
                  <v-checkbox
                    v-model="formTag.tag.filter_detail"
                    label="Saisie détails libre"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-btn color="green" dark @click="sendTag()">{{
                form == 'add' ? 'Ajouter' : 'Sauvegarder'
              }}</v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Suppression d'un tag -->
    <v-dialog v-model="dialogRemoveTag" max-width="480">
      <v-card v-if="tagDeleting">
        <v-card-title>Suppression du tag</v-card-title>
        <v-card-text>
          <p>
            <v-icon color="warning" class="mr-3">mdi-alert</v-icon>Vous vous
            supprimer le tag ?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              tagDeleting = null;
              dialogRemoveFilter = false;
            "
            >{{ $t('actions.cancel') }}</v-btn
          >
          <v-btn color="error" @click="deleteTag(tagDeleting)">{{
            $t('actions.delete')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog attributions des tags -->
    <v-dialog
      v-model="dialogAttributionTags"
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-toolbar color="primary" dark
          ><v-toolbar-title>Attribution des tags</v-toolbar-title></v-toolbar
        >
        <v-card-text>
          <v-row class="pt-5">
            <v-col cols="6">
              <v-select
                v-model="selectedTags"
                label="Select tags"
                item-text="tag.name"
                multiple
                :items="tags"
                hint="Définir des utilisateurs pour ce tag"
                chips
                persistent-hint
                item-value="_id"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="usersTags"
                label="Select user"
                :item-text="(user) => [user.lastname, user.firstname].join(' ')"
                multiple
                :items="users"
                hint="Définir des utilisateurs pour ce tag"
                chips
                persistent-hint
                item-value="id"
              >
                <template v-slot:item="{ item, on, active, attrs }">
                  <v-list-item
                    dense
                    v-on="on"
                    v-bind="attrs"
                    #default="{ active }"
                  >
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        [item.lastname, item.firstname].join(' ')
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-chip color="primary" x-small>
                          {{ item.quality }}
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-alert
          color="blue"
          dark
          class="text-center"
          v-if="selectedTags.length == 0 || usersTags == 0"
          >Veuillez selectionner des users et des tags</v-alert
        >
        <v-card-actions class="justify-end">
          <v-btn text @click="dialogAttributionTags = false">{{
            $t('actions.close')
          }}</v-btn>
          <v-btn
            color="success"
            :disabled="selectedTags.length == 0 || usersTags == 0"
            @click="sendLinkTagsUsers()"
            >{{ $t('actions.save') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog de suppression d'user d'un tag -->
    <v-dialog
      v-model="dialogUsersTag"
      transition="dialog-bottom-transition"
      max-width="400"
    >
      <v-card>
        <v-toolbar color="primary" dark
          ><v-toolbar-title>Utilisateurs</v-toolbar-title></v-toolbar
        >
        <v-card-text v-if="dialogUsersTagSelected">
          <v-row class="pt-5">
            <v-col
              v-for="(item, i) in dialogUsersTagSelected.users"
              :key="i"
              cols="12"
              class="d-flex justify-space-between"
            >
              <div class="d-flex flex-column align-start">
                <p class="ma-0">{{ item.lastname + ' ' + item.firstname }}</p>
                <v-chip x-small color="primary">{{ item.quality }}</v-chip>
              </div>
              <v-btn class="red" dark @click="openDialogRemoveUserTag(item)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            dark
            text
            color="blue lighten-2"
            @click="dialogUsersTag = false"
            >{{ $t('actions.close') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Suppression d'un filtre -->
    <v-dialog v-model="dialogRemoveUserTag" max-width="480">
      <v-card v-if="userTagDeleting">
        <v-card-title>Suppression de l'utilisateur du tag</v-card-title>
        <v-card-text>
          <p>
            <v-icon color="warning" class="mr-3">mdi-alert</v-icon>Vous êtes sur
            de vouloir retirer ce tag à cet utilisateur ?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              userTagDeleting = null;
              dialogRemoveUserTag = false;
            "
            >{{ $t('actions.cancel') }}</v-btn
          >
          <v-btn color="error" @click="removeUserTag(userTagDeleting)">{{
            $t('actions.delete')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-card width="450px" class="mt-16 text-center">
      <v-card-title class="d-flex flex-column align-center">{{
        $t('messagerie.filtres.export')
      }}</v-card-title>
      <v-card-text>
        <date-range-picker
          ref="picker"
          opens="inline"
          :ranges="false"
          singleDatePicker="range"
          :showDropdowns="true"
          :autoApply="true"
          v-model="dates_export_range"
          @update="setDatesExport"
          class="my-4 elevation-8"
        ></date-range-picker>

        <v-alert dark color="red" v-if="dates_export.length < 2"
          >Veuillez choisir deux dates</v-alert
        >
        <v-spacer></v-spacer>
        <v-btn
          @click="exportStats"
          color="success"
          :disabled="dates_export.length < 2"
          >Export Statistiques (Excel)</v-btn
        >
      </v-card-text>
    </v-card> -->
  </v-container>
</template>

<script>
import MessagerieService from '@/Services/MessagerieService';
import TagsMessagerieService from '@/Services/TagsMessagerieService';
import UserService from '@/Services/UserService';
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
let parametrages_messagerie = require('../../../Config/Hotline.json');

export default {
  name: 'HotlineRights',
  components: {
    VToggle: () => import('@/Components/Elements/VuetifyExtend/VToggle'),
    DateRangePicker,
  },
  data() {
    return {
      dates_export_range: {},
      dates_export: [],
      loading: false,
      tags: [],
      formTag: {},
      users: [],
      usersTags: [],
      selectedTags: [],
      form: '',
      valid: false,
      nameRules: [(v) => !!v || 'Name is required'],
      tagDeleting: null,
      dialogRemoveTag: false,
      dialogAttributionTags: false,
      dialogUsersTag: false,
      dialogUsersTagSelected: null,
      dialogRemoveUserTag: false,
      userTagDeleting: null,
      sameNameTag: false,
    };
  },
  computed: {},
  methods: {
    setDatesExport() {
      this.dates_export = Array(
        this.dates_export_range.startDate.toLocaleDateString('en-CA'),
        this.dates_export_range.endDate.toLocaleDateString('en-CA')
      );
    },
    async exportStats() {
      await MessagerieService.exportStats({
        debut: moment(this.dates_export[0]).format('YYYY-MM-DD'),
        fin: moment(this.dates_export[1]).format('YYYY-MM-DD'),
      }).then((res) => {
        let CSV = '';

        let row =
          'Ticket; Client; Demandeur; Etat; Date changement etat; Type de demande; Date demande; Module; Details (Module); Commentaire; Base; Date prévisionnelle; Gestion utilisateur; Suivi; Tag(s);';
        CSV += row + '\r\n';
        let fileName = 'Export fiches';
        res.forEach((fiche) => {
          CSV += fiche.id.replace(/[\r\n;]+/g, ' ') + ';';
          CSV += fiche.client.replace(/[\r\n;]+/g, ' ') + ';';
          CSV += fiche.demandeur.replace(/[\r\n;]+/g, ' ') + ';';
          CSV +=
            this.$t('messagerie.parametrages.' + fiche.etat).replace(
              /[\r\n;]+/g,
              ' '
            ) + ';';
          // si date changement etat est undefined, on met "aucune changement d'état"
          CSV += fiche.date_changement_etat
            ? fiche.date_changement_etat.substr(0, 10).replace(/[\r\n;]+/g, ' ') +
              ';'
            : 'Aucune changement d\'état;';
          CSV +=
            this.$t('messagerie.parametrages.' + fiche.type).replace(
              /[\r\n;]+/g,
              ' '
            ) + ';';
          CSV += fiche.cree.substr(0, 10).replace(/[\r\n;]+/g, ' ') + ';';
          let module = fiche.module;
          if (fiche.sous_module) {
            module += ' | ' + fiche.sous_module.replace(/[\r\n;]+/g, ' ');
          }
          CSV += module.replace(/[\r\n;]+/g, ' ') + ';';
          CSV += fiche.module_details.replace(/[\r\n;]+/g, ' ') + ';';
          CSV += fiche.comment.replace(/[\r\n;]+/g, ' ') + ';';
          CSV += fiche.base.replace(/[\r\n;]+/g, ' ') + ';';
          // si date previsionnelle vide, on met rien
          CSV += fiche.date_previsionnelle
            ? fiche.date_previsionnelle.replace(/[\r\n;]+/g, ' ') + ';'
            : ';';
          CSV += fiche.users
            ? fiche.users.replace(/[\r\n;]+/g, ' ') + ';'
            : ';';
          CSV += fiche.suivi
            ? fiche.suivi.replace(/[\r\n;]+/g, ' ') + ';'
            : ';';
          CSV += fiche.tags
          CSV += '\r\n';
        });

        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        let link = document.createElement('a');
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = 'visibility:hidden';
        link.download = fileName + '.csv';

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    fullname(user) {
      return user.lastname + ' ' + user.firstname;
    },
    /**
     * Permet d'ouvrir la dialog de suppresion de l'user du tag
     * @var user l'user a remove
     */
    openDialogRemoveUserTag(user) {
      this.userTagDeleting = user;
      this.dialogRemoveUserTag = true;
    },
    /**
     * Lors du clic sur le bouton bleu des users d'un tag, affiche la liste des users pour supprimer
     * @var tag le tag
     */
    dialogUsersTagClick(tag) {
      this.dialogUsersTagSelected = {
        tag,
        users: this.users.filter((u) => tag.users.includes(u.id)),
      };
      this.dialogUsersTag = true;
    },
    /**
     * Lors du clic sur le bouton de confirmation de suppression d'un user d'un tag
     */
    async removeUserTag() {
      await TagsMessagerieService.removeUserTags({
        tag: this.dialogUsersTagSelected.tag._id,
        user: this.userTagDeleting.id,
      }).then((res) => {
        this.$nSuccess('user supprimé');
        this.dialogUsersTagSelected.tag.users = this.dialogUsersTagSelected.tag.users.filter(
          (u) => u != this.userTagDeleting.id
        );
        this.dialogUsersTagSelected.users = this.dialogUsersTagSelected.users.filter(
          (u) => u.id != this.userTagDeleting.id
        );
      });
      this.userTagDeleting = null;
      this.dialogRemoveUserTag = false;
    },
    /**
     * Définit le formulaire lors du clic d'un formulaire
     */
    addTagForm() {
      this.form = 'add';
      this.formTag = {
        tag: {
          name: '',
          rights: {
            seeAll: false,
            answerClient: false,
          },
        },
      };
    },
    /**
     * Lors du clic de la modification d'un tag
     * @var tag le tag
     */
    updateTag(tag) {
      this.form = 'update';
      this.formTag = tag;
    },
    /**
     * Permet d'envoyer un tag a ajouter ou supprimer
     */
    async sendTag() {
      if (this.$refs.form.validate() && !this.sameNameTag) {
        await TagsMessagerieService.addUpdateTag({ tag: this.formTag })
          .then((res) => {
            if (this.form == 'add') {
              this.tags.push(res);
              this.$nSuccess('Tag ajouté');
            } else {
              this.$nSuccess('Tag modifié');
            }
            this.form = '';
            this.formTag = {};
          })
          .catch((err) => {});
      }
    },
    /**
     * Permet de supprimer d'un tag
     * @var tag le tag
     */
    async deleteTag(tag) {
      await TagsMessagerieService.deleteTag(tag)
        .then((res) => {
          this.tags = this.tags.filter((t) => t._id != tag._id);
          this.$nSuccess('Tag supprimé');
          this.tagDeleting = null;
          this.dialogRemoveTag = false;
        })
        .catch((err) => {});
    },
    /**
     * Tri du tableau des users
     */
    compareUsers(a, b) {
      if (a.lastname < b.lastname) {
        return -1;
      }
      if (a.lastname > b.lastname) {
        return 1;
      }
      return 0;
    },
    /**
     * Permet de vérifier qu'un tag n'a pas le meme nom que les autres
     */
    checkNotSameNameTag() {
      let tag = this.tags.find(
        (f) => f.tag.name == this.formTag.tag.name && f._id != this.formTag._id
      );
      tag != null ? (this.sameNameTag = true) : (this.sameNameTag = false);
    },
    async sendLinkTagsUsers() {
      await TagsMessagerieService.linkUsersTags({
        tags: this.selectedTags,
        users: this.usersTags,
      }).then((res) => {
        this.selectedTags.forEach((selected_tag) => {
          let tag = this.tags.find((t) => t._id == selected_tag);
          this.usersTags.forEach((u) => {
            if (tag.users.indexOf(u) === -1) {
              tag.users.push(u);
            }
          });
          this.dialogAttributionTags = false;
          this.selectedTags = [];
          this.usersTags = [];
        });
      });
    },
    /**
     * Concatène le nom de l'user
     * @var userId Id de l'user
     */
    usernameUser(userId) {
      if (userId != null) {
        let user = this.users.find((u) => u.id == userId);

        return user.lastname + ' ' + user.firstname;
      } else {
        return '';
      }
    },
  },
  watch: {},
  mounted: async function() {
    //Récupération des utilisateurs à affecter aux tags
    await UserService.getUsers().then((res) => {
      this.users = res.sort(this.compareUsers);
    });
    //Récupération des tags et si aucun tag on crée celui par défaut d'hotline
    await TagsMessagerieService.getTags().then(async (res) => {
      this.tags = res;
      if (typeof res.find((t) => t.tag.default == true) == 'undefined') {
        await TagsMessagerieService.addUpdateTag({
          tag: parametrages_messagerie.default_tag,
        })
          .then((res) => {
            this.tags.push(res);
            this.$nSuccess('Tag par défault ajouté');
          })
          .catch((err) => {});
      }
    });
  },
};
</script>

<!--suppress CssUnusedSymbol -->
<style lang="scss" scoped>
.tag-item {
  margin: 10px 0 10px 0;
  border-radius: 5px;
}
.users-tags {
  color: black !important;
  display: flex;
  align-items: baseline;
}

.v-list-item__title {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.vue-daterange-picker {
  color: #000000;
}
</style>
